import React from "react"
import Layout from "../components/layout"
import { faUserShield } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Seo from "../components/seo"

const PrivacyPolicy = ({ location }) => (
  <Layout location={location}>
    <Seo
      title="プライバシーポリシー"
      description="株式会社ファーストのプライバシーポリシー"
    />
    <div className="container">
      <div
        style={{
          textAlign: "center",
          fontSize: "2rem",
          color: "gray",
          margin: "10px 0px",
        }}
      >
        <FontAwesomeIcon icon={faUserShield} />
      </div>
      <h3 className="title is-4" style={{ color: "gray" }}>
        プライバシーポリシー
      </h3>
      <div className="section">
        <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
          <p>
            当社は、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
          </p>
          <h3 className="title is-5 my-4 has-text-left ">
            １.個人情報の安全管理
          </h3>
          <p>
            当社は、個人情報の保護に関して、組織的、物理的、人的、技術的に適切な対策を実施し、当社の取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
          </p>
          <h3 className="title is-5 my-4 has-text-left">
            ２.個人情報の取得等の遵守事項
          </h3>
          <p>
            当社による個人情報の取得、利用、提供については、以下の事項を遵守します。
          </p>
          <p>(1)個人情報の取得</p>
          <p>
            当社は、当社が管理するインターネットによる情報提供サイト（以下「本サイト」といいます。）の運営に必要な範囲で、本サイトの一般利用者（以下「ユーザー」といいます。）から、個人情報を取得することがあります。
          </p>
          <p>(2)個人情報の利用目的</p>
          <p>
            当社は、当社が取得した個人情報について、法令に定める場合又は本人の同意を得た場合を除き、以下に定める利用目的の達成に必要な範囲を超えて利用することはありません。
          </p>
          <br />
          <ul>
            <li>・問い合わせへの返答</li>
            <li>
              ・不動産の売買契約又は賃貸契約の相手方を探索すること及び売買、賃貸借、仲介、管理等の契約を締結し、契約に基づく役務を提供すること
            </li>
            <li>
              ・お客様との契約の履行、賃貸取引にあっては契約管理、売買取引にあっては契約後の管理・アフターサービス実施
            </li>
            <li>
              ・管理が伴う場合の、マンション等の管理組合で締結した管理委託契約業務履行
            </li>
            <li>
              ・宅地建物取引業法第49条に基づく帳簿及びその資料としての保管
            </li>
            <li>・不動産の売買、賃貸等に関する価格査定 (3)個人情報の提供等</li>
          </ul>
          <br />
          <p>
            当社は、法令で定める場合を除き、本人の同意に基づき取得した個人情報を、本人の事前の同意なく第三者に提供することはありません。
            なお、本人の求めによる個人情報の開示、訂正、追加若しくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
          </p>
          <h3 className="title is-5 my-4 has-text-left">
            ３.個人情報の利用目的の変更
          </h3>
          当社は、前項で特定した利用目的は、予め本人の同意を得た場合を除くほかは、原則として変更しません。但し、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲において、予め変更後の利用目的を公表の上で変更を行う場合はこの限りではありません。
          <h3 className="title is-5 my-4 has-text-left">
            ４.個人情報の第三者提供
          </h3>
          当社は、個人情報の取扱いの全部又は一部を第三者に委託する場合、その適格性を十分に審査し、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
          <h3 className="title is-5 my-4 has-text-left">
            ５.個人情報の取扱いの改善・見直し
          </h3>
          当社は、個人情報の取扱い、管理体制及び取組みに関する点検を実施し、継続的に改善・見直しを行います。
          <h3 className="title is-5 my-4 has-text-left">６.個人情報の廃棄</h3>
          当社は、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
          <h3 className="title is-5 my-4 has-text-left">
            ７.苦情や相談の担当窓口
          </h3>
          <p>
            当社は、個人情報の取扱いに関する担当窓口及び責任者を以下の通り設けます。
          </p>
          <br />
          <p>【株式会社ファースト】</p>
          <p>〒064-0808　札幌市中央区南8条7丁目1782-23　KSコート102号室</p>
          <p>011-596-7480</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
